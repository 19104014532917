import React from 'react'
import { articleImage } from 'images/hulp'
import { Link } from 'gatsby'
import Layout from '../../layouts'
import * as images from 'images/home'
import CtaRow from 'components/cta-row'
import Helmet from 'react-helmet'
import ClientCard from 'components/client-card'
import { metaal_1 } from 'images'
import { window } from 'browser-monads'

import config from '../../config'
const { appUrl, signupRoute } = config

const {
  logos: { woodwatch, petossi, storageShare, codaisseur },
} = images

class metaalPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: '',
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id,
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="animated branche fadeInPage">
          <Helmet>
            <title>Moderne Salarisadministratie voor metaalbedrijven</title>
            <meta
              name="description"
              content="Ontdek moderne en eenvoudige salarisadministratie voor bedrijven in de installatie, metaalbewerking, isolatie of carrosseriesector. ✅ Probeer nu gratis!"
            />
            <meta
              itemprop="name"
              content="Moderne Salarisadministratie voor metaalbedrijven"
            />
            <meta
              itemprop="description"
              content="Ontdek moderne en eenvoudige salarisadministratie voor bedrijven in de installatie, metaalbewerking, isolatie of carrosseriesector. ✅ Probeer nu gratis!"
            />
            <meta
              itemprop="image"
              content="https://employes.nl/static/meta-img.jpg"
            />

            {/* Windows tags */}
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="static/favicon/mstile-144x144.png"
            />

            {/* OpenGraph tags */}
            <meta property="og:url" content="" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Moderne Salarisadministratie voor metaalbedrijven"
            />
            <meta
              property="og:description"
              content="Ontdek moderne en eenvoudige salarisadministratie voor bedrijven in de installatie, metaalbewerking, isolatie of carrosseriesector. ✅ Probeer nu gratis!"
            />
            <meta
              property="og:image"
              content="https://employes.nl/static/meta-img.jpg"
            />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Moderne Salarisadministratie voor metaalbedrijven"
            />
            <meta
              name="twitter:description"
              content="Ontdek moderne en eenvoudige salarisadministratie voor bedrijven in de installatie, metaalbewerking, isolatie of carrosseriesector. ✅ Probeer nu gratis!"
            />
            <meta
              name="twitter:image"
              content="https://employes.nl/static/meta-img.jpg"
            />
          </Helmet>

          <div className="container-md padding-xl">
            <div className="grid center text-center">
              <div className="col-10">
                <h1>Salarisadministratie metaal</h1>
                <p className="streamer large center margin-m-bottom">
                  Employes is een handige salarisadministratie tool voor
                  bedrijven in de installatie, metaalbewerking, isolatie of
                  carrosseriesector
                </p>
                <a className="btn primary lg " href={appUrl} rel="nofollow">
                  Probeer nu gratis
                </a>
                {/*<br />
                <p className="small sky-dark hide-mobile">Je kunt Employes 30 dagen vrijblijvend en gratis testen</p>*/}

                {/*<div className="logo-grid hide-mobile block">
                  <div className="container-sm margin-xs-bottom">
                    <div className="grid yg align-middle logos">
                      {[codaisseur, petossi, storageShare, woodwatch].map((img, idx) => (
                        <div className="col-3 align-middle no-mar" key={idx}>
                          <div className={`logo ${idx === 1 ? 'petossi' : ''}`}>
                            <img src={img} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="container-sm">
                    <div className="grid center text-center yg align-middle">
                      <p className="hide-mobile">We helpen veel verschillende bekende en minder bekenrestaurants-bedrijven.</p>
                    </div>
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>

          <div className="container-md padding-m-top">
            <div className="grid center text-center">
              <div className="col-12">
                <img
                  className="photo"
                  alt="Employes salarisadministratie metaal"
                  src={metaal_1}
                />
              </div>
            </div>
          </div>

          <section className="padding-xl padding-xxl-bottom">
            <div className="container-md flex">
              <div className="text-container">
                <h2>Online salarisadministratie voor metaalbedrijven</h2>
                <p>
                  Als ondernemer in de sector metaal hou je je graag bezig met
                  zaken die ertoe doen. Hierbij hoort echter ook een strakke
                  salarisadministratie. Employes is een handige
                  salarisadministratie tool voor Ontdek moderne en eenvoudige
                  salarisadministratie voor bedrijven in de installatie,
                  metaalbewerking, isolatie of carrosseriesector. Je voert
                  eenvoudig en snel gewerkte uren in en je kunt direct je
                  werknemers verlonen. Daarnaast verstuur je eenvoudig de
                  loonstroken naar je werknemers en betaal je de bedragen met
                  een betaalbestand. En dit alles volgens de goede regels van de
                  PMT (Pensioenfonds metaal en techniek) en de CAO
                </p>

                <h3 className="margin-m-top">
                  Voordelen van online salarisadministratie
                </h3>
                <ul>
                  <li>Directe online verloning</li>
                  <li>Geen salariskennis nodig</li>
                  <li>Online declaraties invoeren</li>
                  <li>Automatisch arbeidscontracten opmaken</li>
                  <li>Actuele regelgeving omtrent de CAO metaal en techniek</li>
                  <li>
                    Koppelingen met diverse boekhoudprogramma’s, zoals Moneybird
                    en Exact Online
                  </li>
                </ul>
                <p>
                  <a className="link mulberry" href={appUrl} rel="nofollow">
                    Probeer Employes gratis uit
                  </a>
                  en zie hoe het voor jou kan werken.
                </p>

                <h3 className="margin-m-top">
                  Maak vrijblijvend kennis met Employes
                </h3>
                <p>
                  Ben je enthousiast over wat je zojuist leest en wil je
                  dolgraag kennismaken met ons slimme salarisadministratie voor{' '}
                  <strong>metaalbedrijven</strong>? Probeer het dan gratis uit
                  en maak geheel vrijblijvend een account aan op de site. Wij
                  zijn ervan overtuigd dat ons online systeem jou diverse
                  voordelen oplevert. Na afloop van je proefperiode betaal je
                  een vast bedrag per maand plus een bepaald bedrag per
                  medewerker. Heb je vragen of behoefte aan advies op maat of
                  twijfel je over de gebruiksvriendelijkheid van de oplossing
                  voor <strong>metaalbedrijven</strong>? Neem contact op met
                  onze professionals. Employes staat je altijd graag te woord en
                  biedt ondersteuning waar dat nodig is.
                </p>
              </div>

              <div className="cta-card right">
                <img src={articleImage} alt="Background image of the card" />
                <p className="eyebrow">Probeer nu gratis</p>
                <h5 className="margin-s-bottom">
                  Ervaar het gemak en probeer Employes gratis uit.
                </h5>
                <a href={appUrl + signupRoute} className="btn primary">
                  Probeer nu
                </a>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default metaalPage
